<template>
  <div class="mt-2 d-flex flex-column">
    <h4 class="ml-2 text--disabled">{{ title }}</h4>
    <h3 class="ml-2">{{ value }}</h3>
  </div>
</template>

<script>
export default {
  props: {
    title: String,
    value: String,
  },
};
</script>

<style></style>
